import { useNavigate, useParams } from '@solidjs/router';
import { Component, createEffect, For, onMount, Show } from 'solid-js';
import { ADD_EMPLOYMENT, ANNUAL_INCOME, NEXT, PREVIOUS } from '~/assets/strings';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Button } from '~/components/common/buttons';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { PresentableEmploymentInformation, RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationStep } from '~/types/RentalApplicationSteps';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { EmploymentHistoryItem } from './EmploymenHistoryItem';

export const EmploymentIncomeStep: Component = () => {
  const { t } = useLocalization();
  const params = useParams();
  const navigate = useNavigate();

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: goToNextStep, isLoading: isGoingToNextStep } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);

  const onUpdateAnnualIncome = (income: string) => {
    updateRentalApplication({
      annualIncome: income,
    });
  };

  const addNewJobHistory = () => {
    const history = [...(presentableRentalApplication()?.employmentHistory ?? []), {}];
    updateRentalApplication({
      employmentHistory: history,
    });
  };

  const onRemoveJobHistory = (index: number) => {
    const history = presentableRentalApplication()?.employmentHistory?.filter((_, i) => i !== index);
    updateRentalApplication({
      employmentHistory: history,
    });
  };

  const onUpdateJobHistoryItem = (historyItem: PresentableEmploymentInformation, index: number) => {
    const history = (presentableRentalApplication()?.employmentHistory ?? []).map((item, i) => (i === index ? historyItem : item));
    updateRentalApplication({
      employmentHistory: history,
    });
  };

  createEffect(() => {
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasEmploymentHistoryError) {
      return;
    }
    goToNextStep({
      currentStep: RentalApplicationStep.IncomeVerification,
      credentials: { id: params.id, password: params.password },
    });
  });

  const onFocus = (event: FocusEvent) => {
    (event.currentTarget as HTMLInputElement).select();
  };

  onMount(() => {
    getRentalApplication({ id: params.id, password: params.password });
  });

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="scrollbar flex h-section3  grow flex-col overflow-y-auto bg-white p-5 pb-3">
        <LabeledTextInput
          class="w-1/3"
          label={t(ANNUAL_INCOME)}
          error={presentableRentalApplication()?.errors?.annualIncomeError}
          value={presentableRentalApplication()?.annualIncome}
          onChange={onUpdateAnnualIncome}
          onFocus={onFocus}
          placeholder="$1,200.00"
          required
        />
        <hr class="my-6" />
        <For each={presentableRentalApplication()?.employmentHistory}>
          {(record, index) => (
            <EmploymentHistoryItem
              index={index()}
              history={record}
              onUpdate={onUpdateJobHistoryItem}
              onDelete={onRemoveJobHistory}
              errors={presentableRentalApplication()?.employmentHistoryErrors?.[index()]}
            />
          )}
        </For>
        <button class="mt-4 text-left text-primary-color hover:underline" onClick={addNewJobHistory}>
          + {t(ADD_EMPLOYMENT)}
        </button>
      </div>
      <div class="fixed bottom-0 left-0 z-20 w-full border-t border-gray-200 bg-white px-4 py-3">
        <div class="flex justify-between">
          <Button type="button" onClick={() => navigate(`/rental-applications/${params.id}/${params.password}/rental-history`)}>
            {t(PREVIOUS)}
          </Button>
          <Button type="button" onClick={validateRentalApplication}>
            {t(NEXT)}
          </Button>
        </div>
      </div>
    </Show>
  );
};

export default EmploymentIncomeStep;
